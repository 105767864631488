import React from 'react'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../context/GlobalContext'

const FooterComp = () => {
    const [pages, setPages] = React.useState("")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    async function getPage() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/page/get",
            post: false,
            token_user: "123",
            admin: false
        })
        console.log(resp.data)
        if (resp.data.success) {
            setPages(resp.data.data)
        }
    }

    React.useEffect(() => {
        getPage()
    }, [])

    return (
        <Box p={4} >
            <Box p={5} borderRadius={4} bgcolor={'#121213'} >
                <Grid container spacing={2} justifyContent='space-between'>
                    <Grid item>
                        <Stack direction={'column'} spacing={2}>
                            <Typography variant='h5' color='white' fontWeight={'bold'} >Useful Links</Typography>
                            {pages ? pages.map((i, key) => {
                                return (
                                    <Typography
                                        sx={{ cursor: 'pointer', textDecoration: 'none' }}
                                        component={Link}
                                        to={`/page/${i.slug}`}
                                        variant='body2' key={key} color="gray">{i.title}</Typography>
                                )
                            }) : <LinearProgress />}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default FooterComp