import { Add, Close, Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, ButtonBase, Chip, Dialog, Divider, IconButton, Paper, Slider, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const ManagePlan = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        dialog: false,
    })
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const [plan, setPlan] = React.useState({
        dall_e: false,
        support_included: false,
        is_trial: false,
        dall_e_size: "256x256",
        whatsapp_bot: false,
        bot_power: 4
    })
    const [planData, setPlanData] = React.useState("")

    async function getPlan() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/plan/get_all",
            admin: true,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            setPlanData(res.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getPlan()
    }, [token])

    async function addPlan() {
        setState({ ...state, loading: true })
        const res = await GLOBAL_CONTEXT.hitAxios({
            post: true,
            path: "/api/plan/add_plan",
            admin: true,
            obj: plan
        })
        setState({ ...state, loading: false })
        if (res.data.success) {
            setState({ ...state, dialog: false, loading: false })
            getPlan()
        }
    }

    async function delPlan(e) {
        if (window.confirm("Are you sure?")) {
            const res = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/plan/del",
                admin: true,
                post: true,
                obj: { id: e }
            })
            if (res.data.success) {
                getPlan()
            }
        }
    }

    return (
        <Box p={2}>

            <Dialog open={state.dialog} fullScreen >
                <Box p={2}>
                    <IconButton onClick={() => setState({ ...state, dialog: false })} >
                        <Close />
                    </IconButton>
                    <Box mt={2}>
                        <Container maxWidth='lg'>
                            <Stack spacing={2} direction={'column'}>

                                <TextField onChange={(e) => {
                                    setPlan({ ...plan, name: e.target.value })
                                }} label='Plan Name' fullWidth size='small' />

                                <TextField onChange={(e) => {
                                    setPlan({ ...plan, description: e.target.value })
                                }} label='Plan Short Description' multiline rows={4} fullWidth size='small' />

                                <TextField
                                    helperText="Put 0 for Trial/Free Plan"
                                    onChange={(e) => {
                                        setPlan({ ...plan, cost: e.target.value })
                                    }} type={'number'} label='Plan Cost' fullWidth size='small' />

                                <TextField onChange={(e) => {
                                    setPlan({ ...plan, word_limit: e.target.value })
                                }} type={'number'} label='Words Limit' fullWidth size='small' />


                                <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                                    <Typography variant='caption'>Dall-E Images Generation?</Typography>
                                    <Switch onChange={(e) => setPlan({ ...plan, dall_e: e.target.checked })} checked={plan.dall_e} />
                                </Stack>

                                {plan.dall_e && <TextField onChange={(e) => {
                                    setPlan({ ...plan, dall_e_limit: e.target.value })
                                }} type={'number'} label='Dall-E Images Limit' fullWidth size='small' />}

                                {plan.dall_e &&
                                    <Stack direction={'column'} spacing={1}>
                                        <Typography variant='caption' color={'gray'} >Choose dall-e image render size</Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <ButtonBase
                                                onClick={() => setPlan({ ...plan, dall_e_size: "256x256" })}
                                                sx={{ borderRadius: 4 }}>
                                                <Chip variant={plan.dall_e_size === "256x256" ? 'filled' : 'outlined'} label='256x256' />
                                            </ButtonBase>
                                            <ButtonBase
                                                onClick={() => setPlan({ ...plan, dall_e_size: "512x512" })}
                                                sx={{ borderRadius: 4 }}>
                                                <Chip variant={plan.dall_e_size === "512x512" ? 'filled' : 'outlined'} label='512x512' />
                                            </ButtonBase>
                                            <ButtonBase
                                                onClick={() => setPlan({ ...plan, dall_e_size: "1024x1024" })}
                                                sx={{ borderRadius: 4 }}>
                                                <Chip variant={plan.dall_e_size === "1024x1024" ? 'filled' : 'outlined'} label='1024x1024' />
                                            </ButtonBase>
                                        </Stack>
                                    </Stack>}


                                <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                                    <Typography variant='caption'>Allow WhatsApp Ai Bot?</Typography>
                                    <Switch onChange={(e) => setPlan({ ...plan, whatsapp_bot: e.target.checked })} checked={plan.whatsapp_bot} />
                                </Stack>


                                <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                                    <Typography variant='caption'>Support Included?</Typography>
                                    <Switch onChange={(e) => setPlan({ ...plan, support_included: e.target.checked })} checked={plan.support_included} />
                                </Stack>

                                <Divider />

                                <Typography variant='caption' color='gray' >Select how power you want this bot: Level {plan.bot_power}</Typography>
                                <Stack direction="row" alignItems="center">
                                    <Slider
                                        marks
                                        min={1}
                                        max={4}
                                        value={plan.bot_power}
                                        onChange={(a, e) => setPlan({ ...plan, bot_power: e })}
                                        valueLabelDisplay="auto"
                                        aria-label="Volume" />
                                </Stack>

                                {plan.bot_power < 4 && <Alert severity="warning">Reducing level may impect on the intelligence of opeanAi</Alert>}

                                {plan.dall_e ?
                                    <LoadingButton
                                        disabled={plan.name && plan.description && plan.cost && plan.word_limit && plan.dall_e_limit ? false : true}
                                        onClick={addPlan}
                                        loading={GLOBAL_CONTEXT.data.loading}
                                        variant='contained' fullWidth size='small' >Save Plan</LoadingButton> :
                                    <LoadingButton
                                        disabled={plan.name && plan.description && plan.cost && plan.word_limit ? false : true}
                                        onClick={addPlan}
                                        loading={GLOBAL_CONTEXT.data.loading}
                                        variant='contained' fullWidth size='small' >Save Plan</LoadingButton>}
                            </Stack>
                        </Container>
                    </Box>
                </Box>
            </Dialog>

            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                <Typography>Manage Plan</Typography>
                <Button
                    onClick={() => setState({ ...state, dialog: true })}
                    startIcon={<Add />} size='small' variant='outlined'>Add</Button>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>


            {planData &&
                <TableContainer >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">Cost</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">Power</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">Word Limit</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">Dall-E</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">Dall-E Limit</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">Dall-E Size</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">WhatsApp Ai Bot</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">Support</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {planData.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{parseInt(row.cost) > 0 ? row.cost : "Free"}</TableCell>
                                    <TableCell align="right">Level {row.bot_power}</TableCell>
                                    <TableCell align="right">{row.word_limit === "-1" ? "Unlimited" : row.word_limit}</TableCell>
                                    <TableCell align="right">{row.dall_e === 0 ? "NA" : "Yes"}</TableCell>
                                    <TableCell align="right">{row.dall_e === 0 ? "NA" : row.dall_e_limit}</TableCell>
                                    <TableCell align="right">{row.dall_e === 0 ? "NA" : row.dall_e_size}</TableCell>
                                    <TableCell align="right">{row.whatsapp_bot === 0 ? "NA" : "Yes"}</TableCell>
                                    <TableCell align="right">{row.support_included === 0 ? "NA" : "Yes"}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => delPlan(row.id)} color='error' size='small' >
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}

        </Box>
    )
}

export default ManagePlan