import { Login } from '@mui/icons-material'
import { ButtonBase, CardMedia, Divider, IconButton, LinearProgress, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import GetWebSet from '../../utils/GetWebSet'
import './front.css'

const Header = () => {
    const { web_set } = GetWebSet()
    const name = web_set ? web_set?.app_name : "loding..."
    const half = name.length / 2
    const history = useHistory()
    return (
        web_set ?
            <Stack alignItems={'center'} p={2} direction={'row'} spacing={2} justifyContent="space-between" >
                <div onClick={() => {
                    history.push('/')
                }} style={{ position: 'relative', cursor: 'pointer' }} >
                    <Box border={2} style={{ borderImage: "linear-gradient(#d21a7b, #8401e6) 5", borderStyle: 'solid' }} p={4}>
                    </Box>
                    <Typography fontWeight={'bold'} variant='h5' style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: `${name.length * 3}%`, alignItems: 'center', display: 'flex' }} >
                        <CardMedia src={`/images/${web_set.logo}`} component="img" height={50} width={50} />
                        {name.slice(0, half)} <a style={{ color: 'gray' }} >{name.slice(half, name.length)}</a> <a style={{ color: '#d21a7b', fontSize: 30 }} >.</a>
                    </Typography>
                    <Divider style={{ position: "absolute", backgroundColor: 'gray', top: "70%", right: 0, left: `${name.length * 3}%` }} />
                </div>
                <ButtonBase component={Link} to="/user" >
                    <a style={{ fontWeight: 'bold', fontSize: "1.5rem" }} className='grad'>Login</a>
                </ButtonBase>
            </Stack > :
            <LinearProgress />
    )
}

export default Header