import axios from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const AdminContext = React.createContext(null)

export const AdminProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        dialog_edit_user: false
    })
    const history = useHistory()

    return (
        <AdminContext.Provider value={{ data, setData }}>
            {props.children}
        </AdminContext.Provider>
    )
}

