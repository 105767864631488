import { Close, DriveFileRenameOutline } from '@mui/icons-material'
import { Chip, Dialog, FormControl, IconButton, InputLabel, Select, Stack, TextField, MenuItem, ButtonBase, Slider, Typography, Divider, Alert } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import { UserContext } from '../../../../context/UserContext'
import { GlobalContext } from '../../../../context/GlobalContext'
import { TypeAnimation } from 'react-type-animation';
import GetLanguages from '../../../../utils/GetLanguages'
import { LoadingButton } from '@mui/lab'
import GetUserByToken from '../../../../utils/GetUserByToken'
import { botpower } from '../../../../utils/BotPower'

const DialogAdd = () => {
    const USER_CONTEXT = React.useContext(UserContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")

    const [enable, setEnable] = React.useState(false)
    const { langs } = GetLanguages()
    const { userdata } = GetUserByToken()
    const [data, setData] = React.useState({
        lang: "English"
    })
    const [err, setErr] = React.useState("")

    async function genOne(e) {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/content/gen_new_chat",
            admin: false,
            post: true,
            obj: data
        })
        if (res.data.success) {
            getData()
        } else {
            setErr(res.data.msg)
            setTimeout(() => {
                setErr("")
            }, 20000);
        }
    }

    async function getData() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/content/get_user_content",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            USER_CONTEXT.setData({ ...USER_CONTEXT.data, dialog_add: false, content_data: res.data.data })
        }
    }


    return (
        <Dialog fullScreen open={USER_CONTEXT.data.dialog_add} >
            <Box p={2}>
                <IconButton onClick={() => USER_CONTEXT.setData({ ...USER_CONTEXT.data, dialog_add: false })} >
                    <Close />
                </IconButton>
            </Box>
            <Box pt={4} pb={4} bgcolor={'black'} mt={2}>
                <Container maxWidth='lg'>

                    <Stack direction={'column'} spacing={2}>
                        <TextField
                            multiline rows={2}
                            variant='standard'
                            helperText="Your prompt here"
                            onChange={(e) => setData({ ...data, case_words: e.target.value })}
                            label={
                                <Stack spacing={0.6} direction={'row'}>
                                    <TypeAnimation
                                        sequence={[
                                            'Wrtite a blog idea for me', // Types 'One'
                                            200, // Waits 1s
                                            'Give me a youtube video idea in trending topics', // Deletes 'One' and types 'Two'
                                            200, // Waits 2s
                                            'Write a song for me', // Types 'Three' without deleting 'Two'
                                            200, // Waits 2s
                                            'Write a best selling line',
                                            200, // Waits 2s
                                            'Write SEO for my blog "how to write code"',
                                            200, // Waits 2s
                                            'etc...',
                                            200, // Waits 2s
                                            'start writing...',
                                            () => {
                                                setEnable(true); // Place optional callbacks anywhere in the array
                                            }
                                        ]}
                                        // wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        style={{ fontSize: 15 }}
                                    />
                                </Stack>
                            }
                            fullWidth size='small' />

                        <Divider />
                        {/* 
                        <TextField
                            onChange={(e) => setData({ ...data, content_words: e.target.value })}
                            label="Your content words" fullWidth size='small' /> */}


                        <FormControl
                            size='small'
                            fullWidth>
                            <InputLabel id="demo-simple-select-label">Select output language</InputLabel>
                            <Select
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={data.lang}
                                label="Select output language"
                                onChange={(e) => setData({ ...data, lang: e.target.value })}
                            >
                                {langs.map((i, key) => {
                                    return (
                                        <MenuItem key={key} value={i}>{i}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <LoadingButton
                            loading={GLOBAL_CONTEXT.data.loading}
                            onClick={genOne}
                            disabled={data.case_words ? false : true}
                            variant='contained'
                            color='secondary'
                            sx={{ textTransform: 'none', borderRadius: 3 }}
                            startIcon={<DriveFileRenameOutline />}
                        >Write this for me</LoadingButton>

                        {
                            err ?
                                JSON.parse(userdata.plan || null).bot_power < 4 ?
                                    <Alert severity="error">{err.replace("This model's", "Your plan").replace("or completion length.", "or upgrade your plan for high level")}</Alert>
                                    : <Alert severity="error">{err}</Alert> : null
                        }
                    </Stack>
                </Container>
            </Box>
        </Dialog>
    )
}

export default DialogAdd