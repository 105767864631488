import { CardMedia, Divider, Grid, ImageList, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player/youtube'
import GetWebSet from '../../utils/GetWebSet'

const Features = () => {
    const { web_set } = GetWebSet()
    const item = ["WhatsApp Chat Bot", "Digital Art", "Product Description", "Video Content", "Blog Writing", "Cover Letter", "Google Ads", "Facebook Ads", "SEO Meta", "Video Description", "Business Idea", "Much More"]
    return (
        <div style={{ padding: "2rem" }} >


            <Stack mt={5} alignItems={'center'} >
                <Box p="3rem" sx={{ borderRadius: "50%", background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(29,29,29,1) 100%, rgba(255,36,36,1) 100%)", borderImage: "linear-gradient(#d21a7b, #8401e6) 5", border: 10, borderColor: "#171717" }}>
                    <Grid container direction={'flex'} alignItems='center' justifyContent={'center'}>
                        <Grid item>
                            <Typography fontSize={"2rem"} fontWeight={'bold'}>Ai<a style={{ color: "#d21a7b" }} >.</a></Typography>
                            <Divider orientation="vertical" flexItem sx={{ border: 2, color: "#797878" }} />
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{ borderLeft: "2px solid #191819", height: "7rem" }} />
            </Stack>


            <Box style={{ borderTop: "2px solid #191819" }} />

            <ImageList
                sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                    gridAutoColumns: "minmax(160px, 1fr)",
                    msOverflowStyle: 'none',
                    overflowX: 'scroll',
                    '::-webkit-scrollbar': {
                        width: '10px'
                    },
                    '::-webkit-scrollbar-track': {
                        background: "transprant",
                        borderRadius: 10
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: "#1B1B1B",
                        borderRadius: 10
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                        background: 'green',
                    }
                }}
            >
                <Stack direction={'row'} justifyContent='flex-start' spacing={8} minHeight={"7rem"}>
                    {item.map((i, key) => {
                        return (
                            <Stack key={key} style={{ position: 'relative', minWidth: 100 }}>
                                <Box pl={2} p={2} style={{ borderImage: "linear-gradient(blue,#852B64, red) 5", borderStyle: 'solid', borderTop: 0, borderBottom: 0, borderRight: 0 }}  >
                                    <Box p={2} minHeight={'3rem'} style={{ background: "linear-gradient(90deg, #121212 0%, rgba(29,29,29,1) 100%, rgba(255,36,36,1) 100%)" }} />
                                </Box>
                                <Typography variant='h6' fontWeight={'bold'} style={{ position: 'absolute', bottom: 0, top: 0 }} >{i}</Typography>
                            </Stack>
                        )
                    })}

                </Stack>
            </ImageList>
            <Stack mb={4} direction={'column'} alignItems='center' spacing={2}>
                <Box component={Link} to="/user" sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }} style={{ borderImage: "linear-gradient(#d21a7b, #8401e6) 5", borderStyle: 'solid' }} p={1} pr={2} pl={2} >
                    <Typography>Get Started for Free</Typography>
                </Box>
                <Typography style={{ color: 'gray', fontSize: 13 }} >No credit card needed</Typography>
            </Stack>

            <Box mb={5} mt={3}>
                <Grid container alignItems={'center'} justifyContent='center'>
                    <Grid item xs={12} sm={8} lg={8}>
                        {web_set && <ReactPlayer width={'100%'} url={web_set.home_video} />}
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}

export default Features