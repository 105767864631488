import React from 'react'
import { Box, ImageList, LinearProgress, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import GetAllPlan from '../../utils/GetAllPlan'
import { Link } from 'react-router-dom'
import GetWebSet from '../../utils/GetWebSet'
import { CheckCircleOutline } from '@mui/icons-material'

const CheckPlans = () => {
    const { plans } = GetAllPlan()
    const { web_set } = GetWebSet()
    return (
        <div style={{ paddingBottom: "2rem", paddingTop: "2rem", paddingLeft: "2rem" }} >
            <Box borderBottom={2} borderColor='#d21a7b' bgcolor={'#121213'} p={4}>
                <Typography fontSize={'2rem'} color={'#777677'} align='right' >Check our <a style={{ color: '#8401e6', fontWeight: 'bold' }} >Plans</a></Typography>
            </Box>
            <Box mt={6} >
                <ImageList
                    sx={{
                        gridAutoFlow: "column",
                        gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                        gridAutoColumns: "minmax(160px, 1fr)",
                        msOverflowStyle: 'none',
                        overflowX: 'scroll',
                        '::-webkit-scrollbar': {
                            width: '10px'
                        },
                        '::-webkit-scrollbar-track': {
                            background: "transprant",
                            borderRadius: 10
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: "#1B1B1B",
                            borderRadius: 10
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                            background: 'green',
                        }
                    }}
                >
                    <Stack direction={'row'} spacing={6}>
                        {plans && web_set ?
                            plans.map((i, key) => {
                                return (
                                    <Box minWidth={200} p={4} sx={{ borderColor: "#8401e6", background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, #191227 100%, rgba(255,36,36,1) 100%)" }} borderRight={3}>
                                        <Stack direction={'column'} spacing={2}>
                                            <Stack direction={'column'} >
                                                <Typography variant='h6' fontWeight={'bold'} ><a style={{ color: "#d21a7b" }} >{i.name}</a> Plan</Typography>

                                                <Typography variant='caption' color={'gray'}>{i.description}</Typography>
                                            </Stack>

                                            <Typography variant='body2' color={'gray'} >{web_set.currency_symbol}{i.cost}</Typography>
                                            {/* <Typography variant='body2' color={'gray'} >{i.word_limit} Words limit</Typography> */}


                                            <Stack direction={'row'} spacing={1} alignItems='center'>
                                                {parseInt(i?.whatsapp_bot) === 0 ? <CheckCircleOutline sx={{ color: 'orangered', height: 18, width: 18 }} /> : <CheckCircleOutline sx={{ color: 'lightgreen', height: 18, width: 18 }} />}   <Typography variant='body2' color={'gray'} >Ai WhatsApp Bot</Typography>
                                            </Stack>

                                            <Stack direction={'row'} spacing={1} alignItems='center'>
                                                {parseInt(i?.dall_e) === 0 ? <CheckCircleOutline sx={{ color: 'orangered', height: 18, width: 18 }} /> : <CheckCircleOutline sx={{ color: 'lightgreen', height: 18, width: 18 }} />}   <Typography variant='body2' color={'gray'} >Dall-E</Typography>
                                            </Stack>


                                            {/* <Typography variant='body2' color={'gray'} >{parseInt(i.dall_e) === 0 ? "NO" : "YES"} Dall-E</Typography> */}
                                            <Typography variant='body2' color={'gray'} >{parseInt(i.dall_e_limit) > 1 ? i.dall_e_limit : "NO"} Dall-E Hits</Typography>
                                            <Typography variant='body2' color={'gray'} >{i.dall_e_size} Dall-E Image Size</Typography>
                                            <Typography variant='body2' color={'gray'} >{i.support_included === 1 ? "" : "NO"} Free Support</Typography>
                                            <Box
                                                component={Link}
                                                to={{ pathname: "/user/checkout", state: i }}
                                                style={{ borderImage: "linear-gradient(#d21a7b, #8401e6) 5", borderStyle: 'solid', cursor: 'pointer', textDecoration: 'none', color: 'white' }} p={1} pr={2} pl={2} >
                                                <Typography>Subscribe Plan</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>

                                )
                            }) :
                            <LinearProgress />}
                    </Stack>
                </ImageList>
            </Box>
        </div>
    )
}

export default CheckPlans