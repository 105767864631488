import axios from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const GlobalContext = React.createContext(null)

export const GlobalProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        loading: false,
        snack: false,
        snack_msg: "",
        campaign_done: false
    })
    const history = useHistory()

    async function hitAxios({ path, obj, admin, post, token_user }) {


        const token = admin ? localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin") : localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")


        setData({ ...data, loading: true })
        return new Promise(async (resolve, reject) => {
            try {
                const resp = post ? await axios.post(url + path, obj, {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                }) : await axios.get(url + path, {
                    headers: {
                        Authorization: "Bearer " + token_user
                    }
                })

                if (resp?.data?.logout) {
                    admin ? localStorage.removeItem(process.env.REACT_APP_TOKEN + "_admin") : localStorage.removeItem(process.env.REACT_APP_TOKEN + "_user")
                    history.push(admin ? "/admin" : "/user")
                }

                if (!resp.data.success) {
                    console.log(resp.data)
                }

                setData({ ...data, loading: false, snack: true, snack_msg: resp?.data?.msg || "Great" })
                setTimeout(() => {
                    setData({ ...data, loading: false, snack: false, snack_msg: resp?.data?.msg || "Great" })
                }, 4000);

                resolve(resp)
            } catch (err) {
                console.log(err)
                setData({ ...data, snack_msg: JSON.stringify(err), snack: true, loading: false })
                setTimeout(() => {
                    setData({ ...data, snack_msg: "", snack: false, loading: false })
                }, 4000);
            }
        })
    }

    return (
        <GlobalContext.Provider value={{ data, setData, hitAxios }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

