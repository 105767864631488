import { Divider, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'

const IntroComp = () => {
    return (
        <Box p={3}>
            <Stack direction={'column'} spacing={2}>
                <Typography variant='h6'  >Introduction</Typography>
                <Typography color={'gray'}>
                    Introducing DallHam AI, an innovative platform powered by OpenAI that combines the intelligence of ChatGPT and Dalle to provide a powerful SAAS system. With DallHam AI, you can access multiple options including a text with image generator and the ability to generate content for a variety of needs by simply giving a text hint to the AI.
                </Typography>

                <Typography color={'gray'}>
                    What's more, DallHam AI also includes a WhatsApp AI ChatBot that leverages the advanced algorithms and technology of OpenAI to automate and elevate your business to the next level. With DallHam AI, you can take advantage of the latest in AI-powered technology to improve your business operations and engage with your audience in a meaningful and effective way.
                </Typography>

                <Typography color={'orange'}>
                    Important:  You will required openAI API keys to use the AI features.
                </Typography>
            </Stack>
            <Box p={1}>
            </Box>
            <Box mt={2} >
                <Typography variant='h6'>Requirements</Typography>
            </Box>
            <ul>
                <Stack direction={'column'} spacing={0.5}>
                    <li style={{ color: 'gray' }} >Vps or Any NodeJs Enabled Shared Hosting</li>
                    <li style={{ color: 'gray' }} >MySQL Database</li>
                    <li style={{ color: 'gray' }} >Domain or Subdomain</li>
                </Stack>
            </ul>
            <Box p={1}>
            </Box>

            <Box mt={2} >
                <Typography variant='h6'>Features</Typography>
            </Box>

            <ul>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >WhatsApp Ai Chatbot</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Unlimited use case for content</li>

                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Generate images using text</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Generate text by giving hit</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >SAAS system</li>

                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Membership palns system</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Attractive frontend</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Contact form for lead generation</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Transaction logs</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >National and international both payment gateways</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >White label using app settings</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Multiple pages with meta tags</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >SEO friendly pages</li>

                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Blog idea</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Content idea</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Ads generator content</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >SEO friendly content</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Professional emails content</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Business ideas</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Youtube video content</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Use it as you want</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >No limits of use</li>
                <li style={{ fontSize: 15, marginBottom: 10, color: 'gray' }} >Much More</li>
            </ul>
        </Box>
    )
}

export default IntroComp