import React from 'react'
import { Box, Stack, Typography, Divider, TextField, Button, ImageListItem, ImageList, LinearProgress, ButtonBase, Dialog, CardMedia, IconButton, Paper, ImageListItemBar, Tooltip, Grid } from '@mui/material'
import { Create, Download, Info, PrecisionManufacturing } from '@mui/icons-material'
import { TypeAnimation } from 'react-type-animation';
import { GlobalContext } from '../../../context/GlobalContext'

const DallE = () => {
    const GLOBAL_CONTENT = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        prompt: ""
    })
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [data, setDate] = React.useState("")

    async function genOne() {
        const resp = await GLOBAL_CONTENT.hitAxios({
            path: "/api/dalle/gen_new",
            admin: false,
            post: true,
            obj: { prompt: state.prompt }
        })
        if (resp.data.success) {
            setState({ ...state, prompt: "" })
        }
    }

    const [show, setShow] = React.useState({
        dialog: false
    })

    async function getData() {
        const resp = await GLOBAL_CONTENT.hitAxios({
            path: "/api/dalle/get_user_dalle",
            admin: false,
            post: false,
            token_user: token
        })
        if (resp.data.success) {
            setDate(resp.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])

    return (
        <Box p={2}>

            <Box sx={{ background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 88%, rgba(0,0,0,1) 91%, rgba(28,2,2,1) 95%, rgba(117,14,14,1) 100%, rgba(255,36,36,1) 100%)" }} bgcolor={'background.default'} p={4} borderRadius={3}>
                <Stack direction={'column'} spacing={3}>
                    <Stack alignItems={'center'} direction={'column'} spacing={2}>
                        <PrecisionManufacturing sx={{ height: 50, width: 50 }} />
                        <Typography color={'gray'} align='center' variant='h5' >Compose your text into the reality</Typography>
                    </Stack>
                    <TextField
                        variant='standard'
                        value={state.prompt}
                        onChange={(e) => setState({ ...state, prompt: e.target.value })}
                        label={
                            <TypeAnimation
                                sequence={[
                                    'A coder writing code', // Types 'One'
                                    500, // Waits 1s
                                    'Create a NFT design for me',
                                    500, // Waits 2s
                                    'Make a logo for my business', // Types 'Three' without deleting 'Two'
                                    500, // Waits 2s
                                    'Create a blog image', // Deletes 'One' and types 'Two'
                                    500, // Waits 2s
                                    'Make car card design',
                                    500, // Waits 2s
                                    'etc...',
                                    500, // Waits 2s
                                    'Write here',
                                    () => {

                                    }
                                ]}
                                // wrapper="div"
                                cursor={false}
                                repeat={0}
                                style={{ fontSize: 15 }} />
                        }
                        helperText="Do not ask for vulgar content you will be banned."
                        fullWidth size='small' />

                    <Button
                        onClick={genOne}
                        disabled={state.prompt ? false : true}
                        startIcon={<Create />} variant='contained' color='secondary' sx={{ borderRadius: 3 }}>Generate</Button>
                </Stack>
            </Box>

            <Dialog open={show.dialog} fullWidth onClose={() => setShow({ ...show, dialog: false })}>
                <div>
                    <CardMedia src={`/dalle/${show.image}`} height='100%' component={'img'} />
                    <Button
                        onClick={() => window.open(`/dalle/${show.image}`)}
                        variant='contained' style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}  >
                        Download
                    </Button>
                </div>

            </Dialog>

            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            <Grid spacing={2} container>

                {data ?
                    [...data].reverse().map((item, key) => (
                        <Grid key={key} xs={12} sm={3} lg={3} item>
                            <Box height={'100%'} sx={{ position: 'relative' }}>
                                <CardMedia
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setShow({ ...show, dialog: true, ...item })
                                    }}
                                    component={'img'} height='100%' src={`/dalle/${item.image}`} />
                                <Tooltip title={item.prompt} >
                                    <Box style={{ position: "absolute", bottom: 0, right: 0, left: 0 }} p={1} bgcolor={'rgba(0, 0, 0, 0.52)'}>
                                        <Typography variant='caption' >{item.prompt}</Typography>
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Grid>
                    ))
                    :
                    <LinearProgress />}
            </Grid>

            {/* {data ?
                <ImageList variant="woven" cols={4} gap={8}>
                    {data.map((item, key) => (
                        <ButtonBase
                            onClick={() => {
                                setShow({ ...show, dialog: true, ...item })
                            }} >
                            <ImageListItem key={key}>
                                <img
                                    src={`/dalle/${item.image}`}
                                    srcSet={`/dalle/${item.image}`}
                                    alt={"hello"}
                                    loading="lazy"
                                />
                                <Tooltip title={item.prompt} >
                                    <ImageListItemBar
                                        subtitle={item.prompt}
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                aria-label={`info about ${item.prompt}`}
                                            >
                                                <Info />
                                            </IconButton>
                                        }
                                    />
                                </Tooltip>
                            </ImageListItem>
                        </ButtonBase>
                    ))}
                </ImageList> :
                <LinearProgress />} */}

        </Box>
    )
}


export default DallE