import { Box, Typography, Stack, Divider, LinearProgress } from '@mui/material'
import React from 'react'
import GetUserByToken from '../../../utils/GetUserByToken'
import GetWebSet from '../../../utils/GetWebSet'
import { Link } from 'react-router-dom'
import { CheckCircleOutline } from '@mui/icons-material'

const ShowPlan = () => {
    const { userdata } = GetUserByToken()
    const { web_set } = GetWebSet()
    const i = JSON.parse(userdata?.plan || null)
    return (
        <Box p={2}>
            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                <Typography>You  opted plan will appear here</Typography>
            </Stack>

            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            {userdata ?
                userdata.plan ?
                    <Box borderRadius={2} p={4} sx={{ borderColor: "#8401e6", background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, #191227 100%, rgba(255,36,36,1) 100%)" }} borderRight={3}>
                        <Stack direction={'column'} spacing={2}>
                            <Typography variant='h6' fontWeight={'bold'} ><a style={{ color: "#d21a7b" }} >{i?.name}</a> Plan</Typography>
                            <Typography variant='body2' color={'gray'} >{web_set.currency_symbol}{i?.cost}</Typography>
                            <Typography variant='body2' color={'gray'} >{i?.word_limit} Words limit Left</Typography>

                            <Stack direction={'row'} spacing={1} alignItems='center'>
                                {parseInt(i?.whatsapp_bot) === 0 ? <CheckCircleOutline sx={{ color: 'orangered', height: 18, width: 18 }} /> : <CheckCircleOutline sx={{ color: 'lightgreen', height: 18, width: 18 }} />}   <Typography variant='body2' color={'gray'} >WhatsApp Bot</Typography>
                            </Stack>

                            <Stack direction={'row'} spacing={1} alignItems='center'>
                                {parseInt(i?.dall_e) === 0 ? <CheckCircleOutline sx={{ color: 'orangered', height: 18, width: 18 }} /> : <CheckCircleOutline sx={{ color: 'lightgreen', height: 18, width: 18 }} />}   <Typography variant='body2' color={'gray'} >Dall-E</Typography>
                            </Stack>

                            <Typography variant='body2' color={'gray'} >{parseInt(i?.dall_e_limit) > 1 ? i?.dall_e_limit : "NO"} Dall-E Hits Left</Typography>
                            <Typography variant='body2' color={'gray'} >{i?.dall_e_size} Dall-E Image Size</Typography>
                            <Typography variant='body2' color={'gray'} >{i?.support_included === 1 ? "" : "NO"} Free Support</Typography>
                            <Box
                                component={Link}
                                to="/"
                                style={{ borderImage: "linear-gradient(#d21a7b, #8401e6) 5", borderStyle: 'solid', cursor: 'pointer', textDecoration: 'none', color: 'white' }} p={1} pr={2} pl={2} >
                                <Typography>Check all Plans</Typography>
                            </Box>
                        </Stack>
                    </Box> : <Typography>No plan found</Typography> : <LinearProgress />}
        </Box>
    )
}

export default ShowPlan