import React from 'react'
import { Box, Button, Container, Dialog, Divider, IconButton, LinearProgress, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Close, Delete, Launch } from '@mui/icons-material'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab'
import { GlobalContext } from '../../../context/GlobalContext'
import moment from 'moment'

const ManagePage = () => {
    const [state, setState] = React.useState({})
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const [pages, setPages] = React.useState("")

    async function addPage() {
        setState({ ...state, loading: true })
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/page/add",
            admin: true,
            post: true,
            obj: state
        })
        if (res.data.success) {
            setState({ ...state, loading: false, dialog: false })
            getPages()
            return
        }
        setState({ ...state, loading: false })
    }

    async function getPages() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            post: false,
            admin: true,
            path: "/api/page/get",
            token_user: token
        })
        console.log(res.data)
        if (res.data.success) {
            setPages(res.data.data)
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getPages()
    }, [token])

    async function delPage(e) {
        if (window.confirm("Are you sure?")) {
            await GLOBAL_CONTEXT.hitAxios({
                path: "/api/page/del",
                post: true,
                admin: true,
                obj: { id: e }
            })
            getPages()
        }
    }

    return (
        <Box p={2}>

            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                <Typography fontWeight={'bold'} >Manage Pages</Typography>
                <Button onClick={() => setState({ ...state, dialog: true })} size='small' variant='outlined' >Add New</Button>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            <Dialog open={state.dialog} fullScreen >
                <Box mb={2} p={2}>
                    <IconButton onClick={() => setState({ ...state, dialog: false })} >
                        <Close />
                    </IconButton>
                </Box>
                <Container maxWidth='lg'>
                    <Stack direction={'column'} spacing={2}>
                        <TextField onChange={(e) => {
                            setState({
                                ...state, title: e.target.value, slug: e.target.value.toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '')
                            })
                        }}
                            helperText={`slug:- ${state.slug || ""}`}
                            label="Page title" fullWidth size='small' />
                        <TextField onChange={(e) => {
                            setState({ ...state, meta: e.target.value })
                        }} label="Page meta" multiline rows={2} fullWidth size='small' />
                        <ReactQuill
                            style={{ backgroundColor: "white", color: 'black' }}
                            theme="snow" onChange={(e) => setState({ ...state, content: e })} />
                        <LoadingButton
                            loading={state.loading}
                            onClick={addPage}
                            disabled={state.title && state.meta && state.content ? false : true}
                            size='small' variant='contained' >
                            Add Page
                        </LoadingButton>
                    </Stack>
                </Container>
            </Dialog>

            <Stack direction={'column'} spacing={2}>
                {pages ?
                    pages.map((i, key) => {
                        return (
                            <Box key={key} border={0.5} borderRadius={2} p={2}>
                                <Stack direction={'row'} spacing={2} justifyContent='space-between'>
                                    <Stack spacing={1} direction={'column'}>
                                        <Typography fontWeight={'bold'} >{i.title}</Typography>
                                        <Typography variant='caption' color={'gray'} >{moment(i.createdAt).format("DD/MM/YYYY - hh:mm A")}</Typography>
                                    </Stack>
                                    <Stack direction={'row'} spacing={1}>
                                        <IconButton
                                            onClick={(() => {
                                                window.open(`/page/${i.slug}`)
                                            })} >
                                            <Launch />
                                        </IconButton>
                                        <IconButton onClick={() => {
                                            delPage(i.id)
                                        }} >
                                            <Delete />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        )
                    })
                    : <LinearProgress />}
            </Stack>

        </Box>
    )
}

export default ManagePage