import { Button, Dialog, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../context/GlobalContext'
import { Close } from '@mui/icons-material'

const LoginUser = () => {
    const [state, setState] = React.useState({})
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const history = useHistory()

    async function tryLogin() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/user/login", post: true, obj: {
                email: state.email,
                password: state.password
            }, admin: true
        })

        if (resp.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_user", resp.data.token)
            history.push('/user')
            return
        }

    }

    async function signUP() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/user/signup_user",
            admin: false,
            post: true,
            obj: state
        })
        if (resp.data.success) {
            setState({ ...state, dialog: false })
        }
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };


    async function sentRecovery() {
        await GLOBAL_CONTEXT.hitAxios({
            path: "/api/user/send_recovery_link_user",
            admin: true,
            post: true,
            obj: { email: state.email_recovery }
        })
        setState({ ...state, dialog_forget: false })
    }


    return (
        <>

            <Dialog fullWidth open={state.dialog_forget} onClose={() => setState({ ...state, dialog_forget: false })}>
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography>{"Enter your email to get your reset password link"}</Typography>
                        <TextField onChange={(e) => setState({ ...state, email_recovery: e.target.value })} label="Email" fullWidth size='small' />
                        <LoadingButton
                            onClick={sentRecovery}
                            loading={GLOBAL_CONTEXT.data.loading}
                            disabled={state.email_recovery ? false : true} >Send Link</LoadingButton>
                    </Stack>
                </Box>
            </Dialog>
            <Dialog open={state.dialog} onClose={() => setState({ ...state, dialog: false })} fullWidth>
                <Box p={2}>
                    <IconButton onClick={() => {
                        setState({ ...state, dialog: false })
                    }} >
                        <Close />
                    </IconButton>
                    <Box mt={2}>
                        <Stack direction={'column'} spacing={2}>
                            <Typography>Fill this form to signup</Typography>
                            <TextField onChange={(e) => {
                                setState({ ...state, name: e.target.value })
                            }} fullWidth label="Name" size='small' />
                            <TextField
                                onChange={(e) => {
                                    setState({ ...state, email: e.target.value })
                                }} fullWidth label="Email" size='small' />


                            <TextField onChange={(e) => {
                                setState({ ...state, password: e.target.value })
                            }} fullWidth label="Password" size='small' />
                            <LoadingButton
                                onClick={signUP}
                                disabled={state.name && validateEmail(state.email) && state.password
                                    ? false : true}
                                loading={GLOBAL_CONTEXT.data.loading}
                                size='small' variant='contained' >Signup</LoadingButton>
                        </Stack>
                    </Box>
                </Box>
            </Dialog>
            <Grid container direction={'column'} height={'100vh'} alignItems='center' justifyContent={'center'}>
                <Grid item>
                    <Container maxWidth='lg'>
                        <Box p={4} borderRadius={4} >
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography sx={{ padding: 5 }} align='center' variant='h6' >User Login</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField size='small' onChange={(e) => setState({ ...state, email: e.target.value })} label="Email" fullWidth />
                                </Grid>
                                <Grid item>
                                    <TextField size='small' onChange={(e) => setState({ ...state, password: e.target.value })} label="Password" type='password' fullWidth />
                                </Grid>
                                <Grid item>
                                    <Stack direction={'column'} spacing={2}>
                                        <Button
                                            disabled={state.email && state.password ? false : true}
                                            size='small' fullWidth variant='contained' onClick={tryLogin} >Login</Button>

                                        <Button
                                            onClick={() => setState({ ...state, dialog: true })}
                                            size='small' color='secondary' fullWidth variant='outlined' >Signup</Button>

                                        <Stack alignItems={'flex-end'}>
                                            <Typography onClick={() => setState({ ...state, dialog_forget: true })} variant='caption' sx={{ cursor: 'pointer' }} >Forget Password?</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </>
    )
}

export default LoginUser