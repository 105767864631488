import React from 'react'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import GetUserByToken from '../../utils/GetUserByToken'
import moment from 'moment'
import { GlobalContext } from '../../context/GlobalContext'

const DashScreen = () => {
    const [dash, setDash] = React.useState("")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")

    async function getDash() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/dash",
            admin: true,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            setDash(res.data)
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getDash()
    }, [token])



    return (
        <Box p={2}>
            {
                dash ?
                    <>
                        <Grid container spacing={2} >
                            <Grid xs={12} sm={6} lg={6} item>
                                <Box bgcolor={'#E6C4FF'} p={2} borderRadius={2}>
                                    <Typography variant='body2' color='black' >Total Users {dash.users}</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} item>
                                <Box bgcolor={'#FFC4DB'} p={2} borderRadius={2}>
                                    <Typography variant='body2' color='black' >Total Plans {dash.plans}</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={12} lg={12} item>
                                <Box bgcolor={'#C4EEFF'} p={2} borderRadius={2}>
                                    <Typography variant='body2' color='black' >Total Orders: {dash.order?.length || 0}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </> : <LinearProgress />
            }
        </Box>
    )
}

export default DashScreen