import { Box, Button } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import ChatBotWork from './components/ChatBotWork'
import CheckPlans from './components/CheckPlans'
import Features from './components/Features'
import FooterComp from './components/FooterComp'
import Header from './components/Header'
import HeroSection from './components/HeroSection'
import HowItWorks from './components/HowItWorks'
import ReadyTo from './components/ReadyTo'
import Truested from './components/Truested'

const FrontEnd = () => {



    return (
        <div style={{ backgroundColor: "black" }} >
            <Header />
            <Container maxWidth='xl'>
                <HeroSection />
                <Truested />
                <Features />
                <ChatBotWork />
                <HowItWorks />
                <CheckPlans />
                <Box mt={2} mb={2} />
                <ReadyTo />
                <Box mt={2} mb={2} />
                <FooterComp />
            </Container>
        </div>
    )
}

export default FrontEnd


