import { Button, Dialog, Grid, TextField, Typography, Stack } from '@mui/material'
import { Box, Container } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../context/GlobalContext'

const LoginAdmin = () => {
    const [state, setState] = React.useState({})
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const history = useHistory()

    async function tryLogin() {

        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/login", post: true, obj: {
                email: state.email,
                password: state.password
            }, admin: true
        })

        if (resp.data.success) {
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_admin", resp.data.token)
            history.push('/admin')
            return
        }

    }

    async function sentRecovery() {
        await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/send_recovery_link_admin",
            admin: true,
            post: true,
            obj: { email: state.email_recovery }
        })
        setState({ ...state, dialog_forget: false })
    }

    return (
        <Grid container direction={'column'} height={'100vh'} alignItems='center' justifyContent={'center'} >
            <Dialog fullWidth open={state.dialog_forget} onClose={() => setState({ ...state, dialog_forget: false })}>
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography>{"Enter your email to get your reset password link"}</Typography>
                        <TextField onChange={(e) => setState({ ...state, email_recovery: e.target.value })} label="Email" fullWidth size='small' />
                        <LoadingButton
                            onClick={sentRecovery}
                            loading={GLOBAL_CONTEXT.data.loading}
                            disabled={state.email_recovery ? false : true} >Send Link</LoadingButton>
                    </Stack>
                </Box>
            </Dialog>
            <Grid item>
                <Container maxWidth='lg'>
                    <Box p={4} borderRadius={4} >
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography sx={{ padding: 5 }} align='center' variant='h6' >Admin Panel</Typography>
                            </Grid>
                            <Grid item>
                                <TextField size='small' onChange={(e) => setState({ ...state, email: e.target.value })} label="Email" fullWidth />
                            </Grid>
                            <Grid item>
                                <TextField size='small' onChange={(e) => setState({ ...state, password: e.target.value })} label="Password" type='password' fullWidth />
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={state.email && state.password ? false : true}
                                    size='small' fullWidth variant='contained' onClick={tryLogin} >Login</Button>
                            </Grid>
                            <Grid item>
                                <Stack alignItems={'flex-end'}>
                                    <Typography onClick={() => setState({ ...state, dialog_forget: true })} variant='caption' sx={{ cursor: 'pointer' }} >Forget Password?</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Grid>
        </Grid>
    )
}

export default LoginAdmin