import { Button, Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'


const InstallationVide = () => {
    return (
        <Box p={4}>
            <Typography sx={{ mb: 3 }} align='center' variant='h6' fontWeight={'bold'} >Installation Video</Typography>
            <Box mb={5} mt={3}>
                <Grid container alignItems={'center'} justifyContent='center'>
                    <Grid item xs={12} sm={12} lg={12}>
                        <iframe width="100%" height={500} src="https://www.youtube.com/embed/RQ32nvOhAug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default InstallationVide