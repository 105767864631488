import { Close, Delete } from '@mui/icons-material'
import { Box, Stack, Typography, Divider, Button, TextField, Dialog, LinearProgress, Chip, Tooltip, Switch, FormControl, Select, InputLabel, MenuItem, IconButton } from '@mui/material'
import React from 'react'
import qrcode from 'qrcode'
import axios from 'axios'
import { GlobalContext } from '../../../context/GlobalContext'
import { Container } from '@mui/system'
import AddDialog from './components/AddDialog'
import { UserContext } from '../../../context/UserContext'
import moment from 'moment'

const WhatsAppComp = () => {
    const [state, setState] = React.useState({
        dialog: false,
        bots: [],
        allow_ai: false
    })
    const [one, setOne] = React.useState({})
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [qr, setQR] = React.useState("")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const USER_CONTEXT = React.useContext(UserContext)
    const [ins, setIns] = React.useState(null)

    const [bots, setBots] = React.useState("")

    function delOne(e) {
        const a = state.bots.filter(i => i.id !== e)
        setState({ ...state, bots: a })
    }

    async function getInstance() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            post: false,
            token_user: token,
            admin: false,
            path: "/api/whatsapp/get_instance_uid"
        })

        if (res.data.success) {
            USER_CONTEXT.setData({ ...USER_CONTEXT.data, instance_list: res.data.data, dialog_add: false })
            setQR("")
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getBots()
        getInstance()
    }, [token])


    async function handleSend() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/whatsapp/add_bot",
            obj: {
                client_id: ins,
                bots: state.bots,
                unique_id: Date.now(),
                footer_text: state.footer_text,
                instruction_text: state.instruction_text,
                allow_ai: state.allow_ai,
                limit_reply: state.limit_reply
            },
            admin: false,
            post: true
        })
    }

    async function getBots() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/whatsapp/get_bots",
            post: false,
            admin: false,
            token_user: token,
        })
        if (resp.data.success) {
            setBots(resp.data.data)
        }
    }



    async function delBot(e) {
        if (window.confirm("Are you sure?")) {
            await GLOBAL_CONTEXT.hitAxios({
                path: "/api/whatsapp/del",
                admin: false,
                post: true,
                obj: { id: e }
            })
            getBots()
        }
    }


    return (
        <Box p={2} >

            <AddDialog />

            <Dialog fullWidth open={state.dialog} onClose={() => setState({ ...state, dialog: false })}>
                <Box p={2}>

                    <Typography fontWeight={'bold'}>Add custom reply</Typography>

                    <Box mt={3}>
                        <Stack direction={'column'} spacing={2}>
                            <TextField
                                onChange={(e) => setOne({ ...one, in: e.target.value })}
                                label="Where to contact you?" helperText="If user sends this text" size='small' fullWidth />

                            <TextField
                                onChange={(e) => setOne({ ...one, out: e.target.value })}
                                label="Please email us at email@domain.com" helperText="Bot will sent this message" size='small' fullWidth />

                            <Stack direction={'row'} justifyContent='flex-end' spacing={2}>
                                <Button
                                    onClick={() => setState({ ...state, dialog: false })}
                                    size='small' >Cancel</Button>
                                <Button
                                    onClick={() => {
                                        setState({
                                            ...state, bots: [...state.bots, {
                                                id: Date.now(),
                                                incoming: one.in,
                                                outgoing: one.out
                                            }], dialog: false
                                        })
                                    }}
                                    disabled={one.in && one.out ? false : true}
                                    size='small' >Add</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </Dialog>

            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                <Stack direction={'column'}>
                    <Typography fontWeight="bold" >WhatsApp Ai Bot</Typography>
                    <Typography variant='caption' color={'gray'} >Set an Ai WhatsApp bot with customer replies.</Typography>
                </Stack>
                <Button onClick={() => USER_CONTEXT.setData({ ...USER_CONTEXT, dialog_add: true })} >Add WhatsApp</Button>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            <Stack direction={'column'} spacing={2}>
                <TextField
                    onChange={(e) => setState({ ...state, limit_reply: e.target.value })} placeholder="Opss. can you please ask in short ?" helperText="If user sends a messages which exceed the limited of Ai then this message will be sent" fullWidth size='small' />
                <TextField
                    onChange={(e) => setState({ ...state, footer_text: e.target.value })}
                    helperText="This text will be appear in the bootm of every reply by Ai"
                    label="Enter a footer message" fullWidth size='small' multiline rows={3} />

                <TextField
                    onChange={(e) => setState({ ...state, instruction_text: e.target.value })}
                    placeholder='It Company/ Social Service worker'
                    helperText="Please give category of your company or work"
                    fullWidth size='small' />

                <Button
                    disabled={!state.limit_reply || !state.footer_text || !state.instruction_text ? true : false}
                    onClick={() => setState({ ...state, dialog: true })}
                    fullWidth size='small' variant='outlined' color='secondary' >Set a custom reply</Button>

                <Stack direction={'row'} spacing={2}>
                    {state.bots.map((i, key) => {
                        return (
                            <Tooltip key={key} title={i.outgoing} >
                                <Chip onDelete={() => delOne(i.id)} label={i.incoming} />
                            </Tooltip>
                        )
                    })}
                </Stack>
                <Divider />

                <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent='space-between'>
                    <Typography fontWeight={state.allow_ai ? "bold" : ""} variant='caption' color={state.allow_ai ? "green" : 'gray'}>Allow Ai to reply?</Typography>
                    <Switch checked={state.allow_ai} onChange={(e) => setState({ ...state, allow_ai: e.target.checked })} />
                </Stack>

                {USER_CONTEXT.data.instance_list ?
                    <FormControl fullWidth>
                        <InputLabel size='small' id="demo-simple-select-label">Select Instance</InputLabel>
                        <Select
                            disabled={USER_CONTEXT.data.instance_list < 1 ? true : false}
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={Select Instance}
                            label="Select Instance"
                            onChange={(e) => setIns(e.target.value)}
                        >
                            {USER_CONTEXT.data.instance_list.map((i, key) => {
                                return (
                                    <MenuItem
                                        disabled={i.status === 'ready to use' ? false : true}
                                        key={key} value={i.client_id}>
                                        <Stack direction={'column'}>
                                            <Typography variant='body2'>{i.instance_number}</Typography>
                                            <Typography variant='caption' color='gray'>{moment(i.created_at).format("DD/MM/YY - hh:mm A")}</Typography>
                                        </Stack>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl> : <LinearProgress />}

                <Button
                    onClick={handleSend}
                    disabled={state.bots.length < 1 && !state.allow_ai ? true : false}
                    variant='contained'  >Start Bot</Button>
            </Stack>


            <Box mt={3}>
                <Divider />

                <Box p={2}>
                    <Typography>Bots History</Typography>
                </Box>

                <Box >
                    <Stack direction={'column'} spacing={2}>
                        {
                            bots ?
                                [...bots].reverse().map((i, key) => {
                                    return (
                                        <Box key={key} boxShadow={5} borderRadius={4} p={2} sx={{ background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }} >
                                            <Stack direction={'row'} spacing={2} justifyContent='space-between'>
                                                <Stack direction={'column'}>
                                                    <Typography color={'white'} fontWeight={'bold'}>Timestamp: {moment(i.createdAt).format("DD/MM/YYYY - hh:mm A")}</Typography>
                                                    <Typography color='gray' textTransform={'capitalize'} variant='caption'>Status: {i.status}</Typography>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <IconButton
                                                        onClick={() => delBot(i.id)}
                                                        color='error' >
                                                        <Delete />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    )
                                }) : <LinearProgress />}
                    </Stack >
                </Box>

            </Box>

        </Box>
    )
}

export default WhatsAppComp