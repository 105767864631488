import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, IconButton, LinearProgress, Typography } from '@mui/material'
import { GlobalContext } from '../../../context/GlobalContext'
import { Delete, ExpandMore } from '@mui/icons-material'
import { Stack } from '@mui/system'

const ContactForm = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const [data, setData] = React.useState("")

    async function getData() {
        const a = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/contact/get_all",
            admin: true,
            post: false,
            token_user: token
        })
        if (a.data.success) {
            setData(a.data.data)
        }
    }

    async function delOne(e) {
        if (window.confirm("Are you sure")) {
            const a = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/contact/del",
                post: true,
                admin: true,
                obj: { id: e }
            })
            if (a.data.success) {
                getData()
            }
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])

    return (
        <Box p={2}>
            <Typography>Frontend Contact Forms</Typography>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            <Stack direction={'column'} spacing={2}>
                {
                    data ?
                        data.map((i, key) => {
                            return (
                                <Accordion key={key} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                    >
                                        <Typography>From: {i.name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack direction={'column'} spacing={1}>
                                            <Typography variant='caption' color={'gray'}>Name: {i.name}</Typography>
                                            <Typography variant='caption' color={'gray'}>Email: {i.email}</Typography>
                                            <Typography variant='caption' color={'gray'}>Mobile: {i.mobile}</Typography>
                                            <Typography variant='caption' color={'gray'}>Message: {i.message}</Typography>
                                        </Stack>
                                        <Stack direction={'row'} justifyContent='flex-end'>
                                            <IconButton onClick={() => delOne(i.id)} color='error' size='small' >
                                                <Delete />
                                            </IconButton>
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                        :
                        <LinearProgress />
                }
            </Stack>
        </Box>
    )
}

export default ContactForm