import { Close } from '@mui/icons-material'
import { Box, ButtonBase, Chip, Dialog, Divider, IconButton, LinearProgress, Stack, TextField } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { AdminContext } from '../../../../context/AdminContext'
import { LoadingButton } from '@mui/lab'
import GetAllPlan from '../../../../utils/GetAllPlan'
import { GlobalContext } from '../../../../context/GlobalContext'

const UserDialog = () => {
    const ADMIN_CONTEXT = React.useContext(AdminContext)
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const { plans } = GetAllPlan()
    const [user, setUser] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const [clicked, setCliked] = React.useState(false)

    async function getUser() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/get_all_user",
            admin: true,
            token_user: token,
            post: false
        })
        if (res.data.success) {
            ADMIN_CONTEXT.setData({ ...ADMIN_CONTEXT.data, user_data: res.data.data, dialog_edit_user: false })
        }
    }

    React.useEffect(() => {
        if (!ADMIN_CONTEXT.data.edit_user_data) {
            return
        }
        setUser(ADMIN_CONTEXT.data.edit_user_data)
    }, [ADMIN_CONTEXT.data.edit_user_data])

    async function updateUser() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/update_user",
            admin: true,
            post: true,
            obj: user
        })
        if (res.data.success) {
            getUser()
        }
    }

    async function updatePlan() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/update_plan",
            admin: true,
            post: true,
            obj: user
        })
        if (res.data.success) {
            ADMIN_CONTEXT.setData({ ...ADMIN_CONTEXT.data, user_data: res.data.data })
            getUser()
        }
    }

    async function delUser(e) {
        if (window.confirm("Are you sure?")) {
            const res = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/admin/del_user",
                admin: true,
                post: true,
                obj: { user_id: e }
            })
            if (res.data.success) {
                ADMIN_CONTEXT.setData({ ...ADMIN_CONTEXT.data, dialog_edit_user: false })
                getUser()
            }
        }
    }

    return (
        <Dialog fullScreen open={ADMIN_CONTEXT.data.dialog_edit_user}>
            <Box p={2}>
                <IconButton onClick={() => ADMIN_CONTEXT.setData({ ...ADMIN_CONTEXT.data, dialog_edit_user: false })} >
                    <Close />
                </IconButton>
                <Box mt={2}>
                    <Container maxWidth='lg'>
                        <Stack direction={'column'} spacing={2}>
                            <TextField
                                onChange={(e) => setUser({ ...user, name: e.target.value })}
                                value={user?.name} label="Name" fullWidth size='small' />

                            <TextField
                                onChange={(e) => setUser({ ...user, email: e.target.value })}
                                value={user?.email} label="Email" fullWidth size='small' />

                            <TextField
                                onChange={(e) => setUser({ ...user, new_pass: e.target.value })}
                                label="Password" helperText="Leave it blank if you dont want to change it" fullWidth size='small' />

                            <LoadingButton
                                loading={GLOBAL_CONTEXT.data.loading}
                                onClick={updateUser}
                                size='small' fullWidth variant='contained' >Update</LoadingButton>


                            <LoadingButton
                                loading={GLOBAL_CONTEXT.data.loading}
                                onClick={() => delUser(user.id)}
                                color='error'
                                size='small' fullWidth variant='contained' >Delete user</LoadingButton>

                            <Divider />
                            <Stack alignItems={'center'} direction={'row'} spacing={2} >
                                {plans ? plans.map((i, key) => {
                                    return (
                                        <ButtonBase
                                            onClick={() => {
                                                setUser({ ...user, plan: JSON.stringify(i), word_left: i.word_limit, dall_e_hit_left: i.dall_e_limit })
                                                setCliked(true)
                                            }}
                                            key={key} sx={{ borderRadius: 4 }}>
                                            <Chip color='secondary' label={i.name} variant={user.plan && JSON.parse(user.plan)?.id === i.id ? "filled" : "outlined"} />
                                        </ButtonBase>
                                    )
                                }) : <LinearProgress />}
                            </Stack>
                            <LoadingButton
                                disabled={clicked ? false : true}
                                loading={GLOBAL_CONTEXT.data.loading}
                                onClick={updatePlan}
                                size='small' fullWidth variant='outlined' >Update Plan</LoadingButton>
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </Dialog>
    )
}

export default UserDialog