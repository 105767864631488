import { Box, ButtonBase, CardMedia, Container, Dialog, Divider, Grid, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { TypeAnimation } from 'react-type-animation';
import { LoadingButton } from '@mui/lab'
import { GlobalContext } from '../../context/GlobalContext'

const HeroSection = () => {
    const [state, setState] = React.useState({
        dialog: false
    })
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    async function addMsg() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/contact/add",
            admin: false,
            post: true,
            obj: state
        })
        if (resp.data.success) {
            setState({ ...state, dialog: false })
        }
    }

    return (
        <Box pl={2} pr={4}>

            <Dialog fullWidth open={state.dialog} onClose={() => setState({ ...state, dialog: false })}>
                <Box p={2}>
                    <Stack direction={'column'} spacing={2}>

                        <Typography variant='h6' fontWeight={'bold'} > Please Fill this form </Typography>
                        <Divider />

                        <TextField onChange={(e) => setState({ ...state, name: e.target.value })} label="Enter your name" fullWidth size='small' />

                        <TextField type={'number'} onChange={(e) => setState({ ...state, mobile: e.target.value })} label="Enter your mobile" fullWidth size='small' />

                        <TextField onChange={(e) => setState({ ...state, email: e.target.value })} label="Enter your email" fullWidth size='small' />

                        <TextField rows={4} multiline onChange={(e) => setState({ ...state, msg: e.target.value })} label="Enter your message" fullWidth size='small' />

                        <LoadingButton
                            loading={GLOBAL_CONTEXT.data.loading}
                            onClick={addMsg}
                            disabled={state.name && state.email && state.mobile && state.msg ? false : true}
                            variant='contained' size='small' color='secondary' >Send</LoadingButton>
                    </Stack>
                </Box>
            </Dialog>


            <Grid container minHeight={'80vh'} alignItems='center' >
                <Grid xs={12} sm={6} lg={6} item>
                    <Typography variant='h2' fontWeight={'bold'} color='#dddcdd' >The <a style={{ color: "#8501e7" }} >Powerful</a> <a style={{ color: "#d21a7b" }} >Ai</a></Typography>
                    <Stack mt={3} direction={'row'} spacing={1}>
                        <Typography fontSize={30} color='#787878' >that can - <a style={{ color: "#8501e7", fontWeight: 'bold' }} >create</a>
                            <TypeAnimation
                                sequence={[
                                    'Anything', // Types 'One'
                                    1000, // Waits 1s
                                    'NTF',
                                    1000, // Waits 2s
                                    'Any Image on your mind', // Types 'Three' without deleting 'Two'
                                    1000, // Waits 2s
                                    'Blogs', // Deletes 'One' and types 'Two'
                                    1000, // Waits 2s
                                    'Anything in your mind',
                                    1000, // Waits 2s
                                    'Whats on your mind',
                                    () => {

                                    }
                                ]}
                                wrapper="div"
                                cursor={true}
                                repeat={0}
                                style={{ fontSize: 30, color: "#e41a82", fontStyle: "oblique" }} />
                        </Typography>
                    </Stack>
                    <Typography sx={{ mt: 2 }} variant='h3' fontWeight={'bold'} color='#dddcdd' >with <a style={{ color: "#25D366" }} >WhatsApp</a> <a style={{ color: "#d21a7b" }} >Ai Based</a>  Chat bot system</Typography>
                </Grid>
                <Grid xs={12} sm={6} lg={6} item>
                    <Stack direction={'column'} spacing={4}>
                        <CardMedia component={'img'} height='100%' src='/static/hero_banner.png' />
                        <Stack direction={'column'} alignItems='flex-end'>
                            <ButtonBase onClick={() => setState({ ...state, dialog: true })} >
                                <Typography sx={{ cursor: 'pointer' }} fontSize={15} fontWeight={'bold'} color='#727273' >send us a <a style={{ color: "#d7d6d6" }} >message</a></Typography>
                            </ButtonBase>
                            <Divider sx={{ width: "12rem", border: 1, color: "#727273", borderRadius: 2, marginTop: 1 }} />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HeroSection