import { AddPhotoAlternate } from '@mui/icons-material'
import { Button, Divider, LinearProgress, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import GetWebSet from '../../../utils/GetWebSet'
import { LoadingButton } from '@mui/lab'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../../../context/GlobalContext'

const AppConfig = () => {
    const { web_set } = GetWebSet()
    const [state, setState] = React.useState({})
    const [web, setWeb] = React.useState("")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)

    React.useEffect(() => {
        setWeb({ ...web_set })
    }, [web_set])


    async function updateWeb(e) {
        e.preventDefault()
        const fd = new FormData()
        fd.append('app_name', web.app_name)
        fd.append('home_video', web.home_video)
        fd.append('file', state.logo_image)
        fd.append('currency_symbol', web.currency_symbol)
        fd.append('openai_key', web.openai_key)
        // fd.append('theme_color', web.theme_color)

        await GLOBAL_CONTEXT.hitAxios({
            path: '/api/web/update_set',
            admin: true,
            post: true,
            obj: fd
        })
    }

    return (
        <Box p={2} >
            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between'>
                <Typography fontWeight={'bold'} >{"App Config"}</Typography>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            {
                web_set ? (
                    <form onSubmit={updateWeb} encType='formdata/multi-part' >
                        <Stack direction={'column'} spacing={2}>
                            <Stack alignItems='center' border={1} borderRadius={2} p={2}>
                                <Button variant='outlined' component="label">
                                    <input
                                        accept="image/png, image/jpeg, image/jpg"
                                        type="file"
                                        hidden
                                        onChange={(e) => setState({ ...state, logo_image: e.target.files[0] })}
                                    />
                                    {
                                        state.logo_image ? (
                                            <img src={URL.createObjectURL(state.logo_image)} style={{ height: 200, width: 200, borderRadius: 5 }} />
                                        ) : (
                                            web.logo ? (
                                                <img src={`/images/${web.logo}`} style={{ height: 200, width: 200, borderRadius: 5 }} />
                                            ) : <AddPhotoAlternate sx={{ height: 200, width: 200 }} />
                                        )
                                    }
                                </Button>
                                <Typography>LOGO</Typography>

                            </Stack>


                            <TextField required onChange={(e) => setWeb({ ...web, app_name: e.target.value })} InputLabelProps={{ shrink: true }} value={web.app_name} label={"App Name"} fullWidth size="small" />
                            <TextField required onChange={(e) => setWeb({ ...web, home_video: e.target.value })} InputLabelProps={{ shrink: true }} value={web.home_video} label={"You video URL"} fullWidth size="small" />
                            <TextField required onChange={(e) => setWeb({ ...web, currency_symbol: e.target.value })} InputLabelProps={{ shrink: true }} value={web.currency_symbol} label={"Currency Symbol"} fullWidth size="small" />
                            <TextField type={'password'} required onChange={(e) => setWeb({ ...web, openai_key: e.target.value })} InputLabelProps={{ shrink: true }} value={web.openai_key} label={"OpenAi Key"} fullWidth size="small" />

                            <LoadingButton loading={state.loading} type="submit" variant='contained' >Save</LoadingButton>

                        </Stack>
                    </form>
                ) : (
                    <LinearProgress />
                )
            }

        </Box>
    )
}

export default AppConfig