import React from 'react'
import { AdminProvider } from '../context/AdminContext'
import AppConfig from './pages/app-config/AppConfig'
import ContactForm from './pages/contact/ContactForm'
import DashScreen from './pages/DashScreen'
import ManagePage from './pages/manage-page/ManagePage'
import ManagePlan from './pages/manage-plan/ManagePlan'
import ManageUser from './pages/manage-user/ManageUser'
import PaymentGateways from './pages/payment-gateways/PaymentGateways'
import ProfileSet from './pages/profile/ProfileSet'
import SMTPConfig from './pages/SMTPConfig'
import TransLog from './pages/trans-log/TransLog'

const DashboardPage = (props) => {
    return (
        <div>
            <AdminProvider>
                {props.page === 0 && <DashScreen />}
                {props.page === 1 && <ManageUser />}
                {props.page === 2 && <ManagePlan />}
                {props.page === 3 && <PaymentGateways />}
                {props.page === 4 && <ManagePage />}
                {props.page === 5 && <SMTPConfig />}
                {props.page === 6 && <TransLog />}
                {props.page === 7 && <AppConfig />}
                {props.page === 8 && <ProfileSet />}
                {props.page === 9 && <ContactForm />}
            </AdminProvider>
        </div>
    )
}

export default DashboardPage