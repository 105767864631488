import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'

const Truested = () => {
    return (
        <div style={{ paddingBottom: "2rem", paddingTop: "2rem", paddingLeft: "2rem" }} >
            <Box bgcolor={'#121213'} p={4}>
                <Typography fontSize={'2rem'} color={'#777677'} align='right' >Our advanced <a style={{ color: '#f40750', fontWeight: 'bold' }} >Ai</a> <a style={{ color: 'white', fontWeight: 'bold' }} >technology</a> helps you to create <a style={{ color: 'white', fontWeight: 'bold' }} >creative content</a> and <a style={{ color: 'white', fontWeight: 'bold' }} >cretive arts</a></Typography>
                <Stack mt={2} direction={'row'} justifyContent='flex-end'>
                    <Box component={Link} to="/user" sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }} style={{ borderImage: "linear-gradient(#d21a7b, #8401e6) 5", borderStyle: 'solid' }} p={1} pr={2} pl={2} >
                        <Typography>Get Started for Free</Typography>
                    </Box>
                </Stack>
            </Box>
        </div>
    )
}

export default Truested