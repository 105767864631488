import React from 'react'
import { Box, CardMedia, Divider, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'

const HowItWorks = () => {
    return (
        <div style={{ paddingBottom: "2rem", paddingTop: "2rem", paddingLeft: "2rem" }} >
            <Box borderBottom={2} borderColor='#d21a7b' bgcolor={'#121213'} p={4}>
                <Typography fontSize={'2rem'} color={'#777677'} align='right' >How It <a style={{ color: '#8401e6', fontWeight: 'bold' }} >Wroks?</a></Typography>
            </Box>
            <Box pr={2} p={2} mt={6}>
                <Grid spacing={2} container minHeight={'60vh'}  >
                    <Grid xs={6} sm={6} lg={6} item>

                        <Box mb={'3rem'} >
                            <Typography variant='h5' fontWeight={'bold'}  >Give an idea about your <a style={{ color: "#8401e6" }} >content</a></Typography>
                        </Box>
                        <CardMedia component={'img'} src='/static/play_img.png' />
                    </Grid>

                    <Grid xs={6} sm={6} lg={6} item>
                        <Box mb={'3rem'} >
                            <Typography variant='h5' fontWeight={'bold'}  ><a style={{ color: "#d21a7b" }} >Boom</a> it will write your idea</Typography>
                        </Box>
                        <CardMedia component={'img'} src='/static/content_ss.png' />
                    </Grid>
                </Grid>
                <Divider />
                <Box mt={"4rem"}>
                    <Grid spacing={2} container minHeight={'60vh'}  >
                        <Grid xs={6} sm={6} lg={6} item>

                            <Box mb={'3rem'} >
                                <Typography variant='h5' fontWeight={'bold'}  >Give hint about your <a style={{ color: "#8401e6" }} >Art</a></Typography>
                            </Box>
                            <CardMedia component={'img'} src='/static/dall_e.png' />
                        </Grid>
                        <Grid xs={6} sm={6} lg={6} item>
                            <Box mb={'3rem'} >
                                <Typography variant='h5' fontWeight={'bold'}  ><a style={{ color: "#d21a7b" }} >Boom</a> it will generate it in image format</Typography>
                            </Box>
                            <CardMedia component={'img'} src='/static/dall_e_content.png' />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div >
    )
}

export default HowItWorks