import { Box, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import { CheckCircleOutline } from '@mui/icons-material'
import { Container } from '@mui/system'
import { Link } from 'react-router-dom'

const ReadyTo = () => {
    return (
        <Container maxWidth='lg' >
            <Box p={10}>
                <Stack alignItems='center' direction='column' spacing={2}>
                    <Typography align='center' variant='h2' fontWeight={'bold'} >Ready to Travel <a style={{ color: "#d21a7b" }} >Future?</a></Typography>
                    <Typography align='center' color="gray" >Write and compose anything 100x faster, more attractive without struggle, Lets go to the future <a style={{ color: "#d21a7b" }} >Future?</a></Typography>
                    <Box component={Link} to="/user" sx={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }} style={{ borderImage: "linear-gradient(#d21a7b, #8401e6) 5", borderStyle: 'solid' }} p={1} pr={2} pl={2} >
                        <Typography>Let's Go Now!</Typography>
                    </Box>
                </Stack>

                <Box mt={4}>
                    <Grid spacing={2} justifyContent='space-between' container>
                        <Grid item>
                            <Stack direction={'row'} spacing={1}>
                                <CheckCircleOutline />
                                <Typography>No Credit Card needed</Typography>
                            </Stack>
                        </Grid>

                        <Grid item>
                            <Stack direction={'row'} spacing={1}>
                                <CheckCircleOutline />
                                <Typography>Free Trial</Typography>
                            </Stack>
                        </Grid>

                        <Grid item>
                            <Stack direction={'row'} spacing={1}>
                                <CheckCircleOutline />
                                <Typography>Struggle Free Unlimited content</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container >
    )
}

export default ReadyTo