import React from 'react'
import { UserContext } from '../../../context/UserContext'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, Button, Divider, Typography, Grid, IconButton, Dialog, Container, Snackbar, Fab } from '@mui/material'
import { Stack } from '@mui/system'
import { Add, Close, ContentCopy, Delete, Visibility } from '@mui/icons-material'
import DialogAdd from './components/DialogAdd'
import { GlobalContext } from '../../../context/GlobalContext'
import moment from 'moment'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css'

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <Box p={2} width={'100%'}  >
                <Grid container justifyContent={'space-between'} spacing={2}>
                    <Grid item>
                        <Typography variant='h5' >All Outputs</Typography>
                    </Grid>
                    <Grid item>
                        <GridToolbarExport />
                    </Grid>
                </Grid>
            </Box>
        </GridToolbarContainer>
    );
}


const PlayArea = () => {
    const USER_CONTEXT = React.useContext(UserContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const [state, setState] = React.useState({
        dialog: false
    })
    const [showQuill, setShowQuill] = React.useState(true)

    async function getData() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/content/get_user_content",
            admin: false,
            post: false,
            token_user: token
        })
        if (res.data.success) {
            USER_CONTEXT.setData({ ...USER_CONTEXT.data, content_data: res.data.data })
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])

    async function delOne(e) {
        if (window.confirm("Are you sure?")) {
            const resp = await GLOBAL_CONTEXT.hitAxios({
                path: "/api/content/del_one",
                admin: false,
                post: true,
                obj: { id: e }
            })
            if (resp.data.success) {
                getData()
            }
        }
    }

    return (
        <Box p={2}>

            <DialogAdd />
            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                <Typography>Compose your content</Typography>
                <Button
                    onClick={() => USER_CONTEXT.setData({ ...USER_CONTEXT.data, dialog_add: true })}
                    startIcon={<Add />} size="small" variant='outlined'>Add New</Button>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            <Dialog open={state.dialog} fullScreen>
                <Box p={2}>
                    {state.dialog &&
                        <Fab
                            onClick={() => navigator.clipboard.writeText(state.edit_data?.content).then(() => alert("Copied to clipboard"))}
                            color="success"
                            sx={{
                                position: 'absolute',
                                bottom: (theme) => theme.spacing(5),
                                right: (theme) => theme.spacing(5),
                            }}
                        >
                            <ContentCopy />
                        </Fab>}
                    <IconButton onClick={() => setState({ ...state, dialog: false })} >
                        <Close />
                    </IconButton>
                    <Box mt={2} mb={2}>
                        <Container maxWidth='lg'>
                            <Stack spacing={2} direction={'column'}>
                                <Stack bgcolor={'black'} p={2} borderRadius={2} direction={'column'} justifyContent='space-between'>
                                    <Typography variant='body2' color={'gray'}>Case: {state.edit_data?.case_words}</Typography>
                                </Stack>
                                <ReactQuill
                                    style={{ backgroundColor: 'white', color: 'black' }}
                                    theme="snow" value={state.edit_data?.content} />
                            </Stack>
                        </Container>
                    </Box>
                </Box>
            </Dialog>


            {USER_CONTEXT.data.content_data &&
                <Box height='80vh'>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={[...USER_CONTEXT.data.content_data].reverse()}
                        columns={[
                            { headerName: "Case", field: 'case_words', flex: 1, },
                            {
                                headerName: "Words", field: 'content', flex: 1, renderCell: data => {
                                    return (
                                        data.row.content?.split(" ")?.length
                                    )
                                }
                            },
                            // {
                            //     headerName: "Level", field: 'level', flex: 1, renderCell: data => {
                            //         return (
                            //             data.row.level === "text-ada-001" && 1 || data.row.level === "text-babbage-001" && 2 || data.row.level === "text-curie-001" && 3 || data.row.level === "text-davinci-003" && 4
                            //         )
                            //     }
                            // },
                            {
                                headerName: "Added", field: 'createdAd', flex: 1, renderCell: data => {
                                    return (
                                        moment(data.row.createdAt).format("DD-MM-YYYY")
                                    )
                                }
                            },
                            {
                                headerName: "Status", field: 'status', flex: 1, renderCell: data => {
                                    return (
                                        <Typography color={data.row.status === "failed" && "red"} sx={{ textTransform: "capitalize" }} variant='body2'>{data.row.status}</Typography>
                                    )
                                }
                            },
                            {
                                headerName: "Menu", field: 'id', flex: 1, renderCell: data => {
                                    return (
                                        data.row.status === 'success' ?
                                            <IconButton
                                                onClick={() => setState({ ...state, dialog: true, edit_data: data.row })}
                                                color='info' >
                                                <Visibility />
                                            </IconButton> :
                                            <IconButton onClick={() => delOne(data.row.id)}
                                                color='error' >
                                                <Delete />
                                            </IconButton>
                                    )
                                }
                            },

                            {
                                headerName: "Delete", field: 'uid', flex: 1, renderCell: data => {
                                    return (
                                        <IconButton onClick={() => delOne(data.row.id)}
                                            color='error' >
                                            <Delete />
                                        </IconButton>
                                    )
                                }
                            }
                        ]}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </Box>}
        </Box>
    )
}

export default PlayArea