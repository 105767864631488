import axios from 'axios'
import React from 'react'

const GetUserByToken = () => {
    const [userdata, setuserdata] = React.useState("")
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    React.useEffect(() => {
        if (!token) {
            return
        }
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get_user_by_token`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            setuserdata(res.data.data)
        })
            .catch((err) => console.log(err))
    }, [token])
    return { userdata }
}

export default GetUserByToken