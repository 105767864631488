import { Divider, Grid, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'

const IntroComp = () => {
    return (
        <Box p={3}>

            <Typography fontWeight={'bold'} align='center' variant='h6'>Quick Start with features</Typography>

            <Box mb={5} mt={3}>
                <Grid container alignItems={'center'} justifyContent='center'>
                    <Grid item xs={12} sm={12} lg={12}>
                        <iframe width="100%" height={500} src="https://www.youtube.com/embed/YurIzuml_bg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}

export default IntroComp