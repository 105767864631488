import React from 'react'
import { Box, CardMedia, Divider, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { DoneAll } from '@mui/icons-material'

const ChatBotWork = () => {
    return (
        <div style={{ paddingBottom: "2rem", paddingTop: "2rem", paddingLeft: "2rem" }} >
            <Box borderBottom={2} borderColor='#d21a7b' bgcolor={'#121213'} p={4}>
                <Typography fontSize={'2rem'} color={'#777677'} align='right' >How <a style={{ color: '#25D366', fontWeight: 'bold' }} >WhatsApp</a> Ai Chat bot works?</Typography>
            </Box>
            <Box pr={2} p={2} mt={6}>

                <Grid alignItems={'flex-start'} align='justify' container justifyContent={'space-between'} spacing={4} >

                    <Grid item xs={12} lg={6} sm={6} >
                        <Box >
                            <Box color='black' style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, borderBottomRightRadius: 20 }} bgcolor='#f7f6f6' p={2}>
                                <Typography><a style={{ fontWeight: 'bold' }} >Client</a>: Hi i am confused can you suggest me why do i buy your product.</Typography>
                            </Box>
                            <Box>
                                <Box mt={2} color='black' style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} bgcolor='#d5f8c3' p={2}>
                                    <Typography><a style={{ fontWeight: 'bold' }} >WhatsApp Ai Bot</a>: Absolutely! Our product has [list the key benefits or features of the product] which make it an excellent choice for [explain the target audience]. Plus, [mention any special offers or discounts currently available]. Let me know if you have any other questions or concerns, I'd be happy to assist you further.
                                        {<br />}
                                        {<br />}
                                        [This is a footer customer reply set by company]
                                    </Typography>
                                </Box>
                                <Stack direction={'row'} justifyContent='flex-end'>

                                    <DoneAll sx={{ color: 'green' }} />
                                </Stack>
                            </Box>
                            <Box mt={1} color='black' style={{ borderTopRightRadius: 20, borderTopLeftRadius: 20, borderBottomRightRadius: 20 }} bgcolor='#f7f6f6' p={2}>
                                <Typography><a style={{ fontWeight: 'bold' }} >Client</a>: Wow i am impressed please contact me soon i want to buy.</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={6} sm={6}>
                        <Box>
                            <Typography color='gray' >WhatsApp Ai chat bot is an automated messaging feature that can understand and respond to user queries using artificial intelligence that can get the sales from your user easily. Because Its trained on large datasets, customized to specific needs, and perform a range of tasks. The chat bot can continuously learn and improve, and provide links and media to users.</Typography>

                            <Box mt={4}>
                                <li>Set a WhatsApp Ai bot</li>
                                <li>Give the Ai some information about your company work</li>
                                <li>Add our footer custom message</li>
                                <li>Scan QR code from your whatsapp number</li>
                                <li>Boom all set for the WhstaApp Ai Bot</li>
                            </Box>


                        </Box>

                    </Grid>
                </Grid>


            </Box>
        </div >
    )
}

export default ChatBotWork