import { Divider, Typography, Stack, LinearProgress, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import moment from 'moment'
import { Delete } from '@mui/icons-material'

const InstangeHistory = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")
    const [state, setState] = React.useState({})

    async function getData() {
        const resp = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/whatsapp/get_instance_uid",
            admin: false,
            post: false,
            token_user: token
        })
        if (resp.data.success) {
            setState({ ...state, data: resp.data.data })
        }
    }

    React.useEffect(() => {
        if (!token) {
            return
        }
        getData()
    }, [token])


    async function delCam(e) {
        if (window.confirm("Are you sure?")) {
            await GLOBAL_CONTEXT.hitAxios({
                post: true,
                path: "/api/whatsapp/del_ins",
                admin: false,
                obj: { id: e }
            })
            getData()
        }
    }


    return (
        <Box p={2} >
            <Stack direction={'row'} alignItems='center'>
                <Typography fontWeight="bold" >Update Profile</Typography>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>

            {
                state.data ? (
                    <Stack direction={'column'} spacing={2}>
                        {
                            state.data ?
                                [...state.data].reverse().map((i, key) => {
                                    return (
                                        <Box key={key} boxShadow={5} borderRadius={4} p={2} sx={{ background: "radial-gradient(circle, rgba(251,63,152,1) 0%, rgba(36,24,115,1) 100%)" }} >
                                            <Stack direction={'row'} spacing={2} justifyContent='space-between'>
                                                <Stack direction={'column'}>
                                                    <Typography color={'white'} fontWeight={'bold'}>Timestamp: {moment(i.createdAt).format("DD/MM/YYYY - hh:mm A")}</Typography>
                                                    <Typography color={i.status === 'completed' ? '#6CFF92' : "gray"} textTransform={'capitalize'} variant='caption'>Status: {i.status === 'completed' ? "completed" : i.status}</Typography>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <IconButton
                                                        onClick={() => delCam(i.id)}
                                                        color='error' >
                                                        <Delete />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    )
                                }) : <LinearProgress />}
                    </Stack >
                ) : <LinearProgress ></LinearProgress>
            }

        </Box>
    )
}

export default InstangeHistory