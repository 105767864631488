import axios from 'axios'
import React from 'react'

const GetAllPlan = () => {
    const [plans, setState] = React.useState("")
    async function fetchData() {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/plan/get_all`)
        setState(res.data.data)
    }
    React.useEffect(() => {
        fetchData()
    }, [])
    return { plans }
}

export default GetAllPlan