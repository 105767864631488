import axios from 'axios'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const UserContext = React.createContext(null)

export const UserProvider = (props) => {
    const url = process.env.REACT_APP_BASE_URL
    const [data, setData] = React.useState({
        dialog_add: false
    })
    const history = useHistory()

    return (
        <UserContext.Provider value={{ data, setData }}>
            {props.children}
        </UserContext.Provider>
    )
}

