import React from 'react'
import DallE from './pages/dall-e/DallE'
import InstangeHistory from './pages/instance-history/InstangeHistory'
import ShowPlan from './pages/plan/ShowPlan'
import PlayArea from './pages/play/PlayArea'
import ProfileUser from './pages/profile/ProfileUser'
import Rephrase from './pages/rephrase/Rephrase'
import UserDash from './pages/UserDash'
import WhatsAppComp from './pages/whatsapp/WhatsAppComp'

const UserDashPage = (props) => {
    return (
        <div>
            {props.page === 0 && <UserDash />}
            {props.page === 1 && <PlayArea />}
            {props.page === 2 && <WhatsAppComp />}
            {props.page === 3 && <DallE />}
            {props.page === 4 && <ShowPlan />}
            {props.page === 5 && <ProfileUser />}
            {props.page === 6 && <InstangeHistory />}
        </div>
    )
}

export default UserDashPage