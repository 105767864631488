import React from 'react'
import { Box, Divider, Typography, CircularProgress, Grid, IconButton } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Stack } from '@mui/system'
import { GlobalContext } from '../../../context/GlobalContext'
import { AdminContext } from '../../../context/AdminContext';
import moment from 'moment'
import { Login, Visibility } from '@mui/icons-material'
import UserDialog from './components/UserDialog';
import axios from 'axios'

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <Box p={2} width={'100%'}  >
                <Grid container justifyContent={'space-between'} spacing={2}>
                    <Grid item>
                        <Typography variant='h5' >All Users</Typography>
                    </Grid>
                    <Grid item>
                        <GridToolbarExport />
                    </Grid>
                </Grid>
            </Box>
        </GridToolbarContainer>
    );
}

const ManageUser = () => {
    const GLOBAL_CONTEXT = React.useContext(GlobalContext)
    const ADMIN_CONTEXT = React.useContext(AdminContext)
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
    const [state, setState] = React.useState({})

    async function getUser() {
        const res = await GLOBAL_CONTEXT.hitAxios({
            path: "/api/admin/get_all_user",
            admin: true,
            token_user: token,
            post: false
        })
        if (res.data.success) {
            ADMIN_CONTEXT.setData({ ...ADMIN_CONTEXT.data, user_data: res.data.data })
        }
    }

    function direcLogin(e) {
        setState({ ...state, loading: true })
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/direct_login`, {
            uid: e
        }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            setState({ ...state, loading: false })
            localStorage.setItem(process.env.REACT_APP_TOKEN + "_user", res.data.token)
            window.open('/user')
        }).catch((err) => console.log(err))
    }


    React.useEffect(() => {
        if (!token) {
            return
        }
        getUser()
    }, [token])

    return (
        <Box p={2}>
            <Stack direction={'row'} alignItems='center'>
                <Typography>Manage User</Typography>
            </Stack>
            <Box mt={2} mb={2}>
                <Divider />
            </Box>
            <UserDialog />


            {ADMIN_CONTEXT.data.user_data &&
                <Box height='80vh'>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={[...ADMIN_CONTEXT.data.user_data].reverse()}
                        columns={[
                            {
                                headerName: "Login", field: 'uid', flex: 1, renderCell: data =>
                                    state.loading ? (
                                        <CircularProgress size={25} />
                                    ) : (
                                        <IconButton
                                            onClick={() => direcLogin(data.row.uid)}
                                        >
                                            <Login />
                                        </IconButton>
                                    )
                            },
                            { headerName: "Name", field: 'name', flex: 1, },
                            { headerName: "Email", field: 'email', flex: 1, },
                            {
                                headerName: "Signed up", field: 'createdAt', flex: 1, renderCell: data => {
                                    return (
                                        moment(data.row).format("DD-MM-YYYY")
                                    )
                                }
                            },
                            {
                                headerName: "Plan", field: 'plan', flex: 1, renderCell: data => {
                                    return (
                                        data?.row.plan ? JSON.parse(data.row.plan)?.name : "NA"
                                    )
                                }
                            },
                            {
                                headerName: "Dall-E Left", field: 'dall_e_hit_left', flex: 1, renderCell: data => {
                                    return (
                                        parseInt(data?.row.dall_e_hit_left) > 0 ? data?.row.dall_e_hit_left : "NA"
                                    )
                                }
                            },
                            {
                                headerName: "Words Left", field: 'word_left', flex: 1, renderCell: data => {
                                    return (
                                        parseInt(data?.row.word_left) > 0 ? data?.row.word_left : "NA"
                                    )
                                }
                            },
                            {
                                headerName: "Edit", field: 'id', flex: 1, renderCell: data => {
                                    return (
                                        <IconButton
                                            onClick={() => ADMIN_CONTEXT.setData({ ...ADMIN_CONTEXT.data, dialog_edit_user: true, edit_user_data: data.row })}
                                            color='info' >
                                            <Visibility />
                                        </IconButton>
                                    )
                                }
                            }
                        ]}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </Box>}
        </Box>
    )
}

export default ManageUser