import React from 'react'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import GetUserByToken from '../../utils/GetUserByToken'
import moment from 'moment'

const UserDash = () => {
    const { userdata } = GetUserByToken()
    return (
        <Box p={2}>
            {
                userdata ?
                    <>
                        <Grid container spacing={2} >
                            <Grid xs={12} sm={6} lg={6} item>
                                <Box bgcolor={'#E6C4FF'} p={2} borderRadius={2}>
                                    <Typography variant='body2' color='black' >Hi, {userdata.name}</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} item>
                                <Box bgcolor={'#FFC4DB'} p={2} borderRadius={2}>
                                    <Typography variant='body2' color='black' >You joined on {moment(userdata.createdAt).format("DD-MM-YYYY")}</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} item>
                                <Box bgcolor={'#C4EEFF'} p={2} borderRadius={2}>
                                    <Typography variant='body2' color='black' >Words Left: {parseInt(userdata?.word_left) > 0 ? userdata?.word_left : "NA"}</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6} lg={6} item>
                                <Box bgcolor={'#FFD6C4'} p={2} borderRadius={2}>
                                    <Typography variant='body2' color='black' >Dall-E Hit left: {parseInt(userdata.dall_e_hit_left) > 0 ? `${userdata.dall_e_hit_left}` : 'NA'}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </> : <LinearProgress />
            }
        </Box>
    )
}

export default UserDash